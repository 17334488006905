import React from 'react';
import Footer from "../../../footer/Footer";
import Filter from "../../../filter/Filter";

const PassengerVehicle = () => {
    return (
        <div>
            <Filter type={'passenger'}/>
            <Footer/>
        </div>
    );
};

export default PassengerVehicle;