import React, {useEffect, useState} from 'react';
import s from './ModalEditCar.module.scss'
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast
} from "@chakra-ui/react";
import {useStore} from "../../../store/store";
import {axiosCarsOne, axiosCarUpdate} from "../../../axios/cars";

const ModalEditCar = () => {
    const {editModalState, closeModalEdit} = useStore()
    const [car, setCar] = useState()
    const [newCarValue, setNewCarValue] = useState({price: '', car_lot: '', year: '', mileage: ''})
    const toast = useToast()
    useEffect(() => {
        if (editModalState.car_id) {
            axiosCarsOne(editModalState.car_id).then((data) => {
                setCar(data.data)
            })
        }
    }, [editModalState]);

    const closeModal = () => {
        closeModalEdit()
        setNewCarValue({price: '', car_lot: '', year: '', mileage: ''})
    }

    const updateCar = async () => {
        let dataToSend = {
            car_id: car.id,
        }
        if (newCarValue.price) {
            dataToSend.price = +newCarValue.price
        }
        if (newCarValue.year) {
            dataToSend.year = +newCarValue.year
        }
        if (newCarValue.car_lot) {
            dataToSend.car_lot = +newCarValue.car_lot
        }
        if (newCarValue.mileage) {
            dataToSend.mileage = +newCarValue.mileage
        }

        const deletePromise = axiosCarUpdate(dataToSend);
        toast.promise(deletePromise, {
            success: {title: 'Успешно', description: 'ОБНОВИТЕ СТРАНИЦУ', duration: '5000'},
            error: {title: 'Ошибка изменения', description: 'Ошибка изменения записи', duration: '1000'},
            loading: {title: 'Изменяется...', description: 'Пожалуйста, подождите'},
        });
        try{
            await axiosCarUpdate(dataToSend);
            closeModalEdit()
        }
        catch (e) {

        }
    }

    return (
        <div className={s.edit}>
            <Modal onClose={closeModal} size={"xl"} isOpen={editModalState.state}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Редактировать {car?.brand?.value} {car?.model?.value}, {car?.year}
                        <div>VIN - {car?.vin}</div>
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <div className={s.body}>
                            <label>
                                <div>
                                    <div style={{fontSize: '12px'}}>Цена</div>
                                    <Input
                                        style={{marginBottom: '10px'}}
                                        size={'xs'}
                                        value={newCarValue.price}
                                        onInput={(e) => setNewCarValue({
                                            ...newCarValue,
                                            price: e.target.value.replace(/[^0-9]/g, '')
                                        })}
                                    />
                                </div>
                            </label>
                            <label>
                                <div>
                                    <div style={{fontSize: '12px'}}>Партия</div>
                                    <Input
                                        style={{marginBottom: '10px'}}
                                        size={'xs'}
                                        value={newCarValue.car_lot}
                                        onInput={(e) => setNewCarValue({
                                            ...newCarValue,
                                            car_lot: e.target.value.replace(/[^0-9]/g, '')
                                        })}
                                    />
                                </div>
                            </label>
                            <label>
                                <div>
                                    <div style={{fontSize: '12px'}}>Год</div>
                                    <Input
                                        style={{marginBottom: '10px'}}
                                        size={'xs'}
                                        value={newCarValue.year}
                                        onInput={(e) => setNewCarValue({
                                            ...newCarValue,
                                            year: e.target.value.replace(/[^0-9]/g, '')
                                        })}
                                    />
                                </div>
                            </label>
                            <label>
                                <div>
                                    <div style={{fontSize: '12px'}}>Пробег</div>
                                    <Input
                                        style={{marginBottom: '10px'}}
                                        size={'xs'}
                                        value={newCarValue.mileage}
                                        onInput={(e) => setNewCarValue({
                                            ...newCarValue,
                                            mileage: e.target.value.replace(/[^0-9]/g, '')
                                        })}
                                    />
                                </div>
                            </label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{marginRight: '15px'}} onClick={closeModal}>Закрыть</Button>
                        <Button colorScheme={'blue'} onClick={updateCar}>Сохранить</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default ModalEditCar;