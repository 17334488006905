import React, {useEffect, useState} from 'react';
import s from './FilterMenu.module.scss'
import {Button} from "@chakra-ui/react";
import MySelect from "./inputs/MySelect";
import InputFromTo from "./inputs/InputFromTo";
import {useStore} from "../../../store/store";
import {axiosGetFilters,} from "../../../axios/filters";
import MySpinner from "../../UI/mySpinner";

const FilterMenu = ({showActualCar}) => {
    const {resetSelectedFilters, selectedFilters,updateSelectedFilters} = useStore()

    const [filterOptions, setFilterOptions] = useState(null)
    const [loading, setLoading] = useState(false)
    const sendData = () => {
        showActualCar()
    }
    useEffect(() => {
        setLoading(true)
        axiosGetFilters({}).then((data) => {
            setFilterOptions(data.data)
        }).finally(() => setLoading(false))
    }, []);

    const changeForm = (e) => {
        if (e.target.hasAttribute('data-type')) {
            if (e.target.getAttribute('data-type') === 'Марка') {
                axiosGetFilters({"brand_id": e.target.value}).then((data) => {
                    updateSelectedFilters({...selectedFilters,brand_id:e.target.value,model_id:''})
                    setFilterOptions(data.data)
                })
            }
            if (e.target.getAttribute('data-type') === 'Модель') {
                axiosGetFilters({"model_id": e.target.value}).then((data) => {
                    setFilterOptions(data.data)
                })
            }
        }
    }

    return (
        <>
            {
                loading
                    ? <MySpinner style={{minHeight: '10vh'}}/>
                    : filterOptions &&
                    <form onChange={changeForm}>
                        <div className={[s.filterMenu, 'br'].join(' ')}>
                            <div className={s.inputs}>
                                <MySelect propsLabel={'Марка'} propsOptions={filterOptions['brands']}
                                          store_value={'brand_id'}/>
                                <MySelect propsLabel={'Модель'} propsOptions={filterOptions['models']}
                                          store_value={'model_id'}/>
                                <MySelect propsLabel={'Состояние'} propsOptions={filterOptions['car_condition']}
                                          store_value={'car_condition_id'}/>
                                <MySelect propsLabel={'Коробка передач'} propsOptions={filterOptions['transmission']}
                                          store_value={'transmission_id'}/>
                                <MySelect propsLabel={'Двигатель'} propsOptions={filterOptions['engine']}
                                          store_value={'engine_id'}/>
                                <MySelect propsLabel={'Тип Кузова'} propsOptions={filterOptions['car_body']}
                                          store_value={'car_body_id'}/>
                                <InputFromTo propsLabel={'Год'} type={'year'}/>
                                <InputFromTo propsLabel={'Стоимость'} type={'price'}/>
                                <InputFromTo propsLabel={'Пробег'} type={'mileage'}/>
                                <MySelect propsLabel={'Вид топлива'} propsOptions={filterOptions['fuel_type']}
                                          store_value={'fuel_type_id'}/>
                                <MySelect propsLabel={'Цвет кузова'} propsOptions={filterOptions['color']}
                                          store_value={'color_id'}/>
                                <MySelect propsLabel={'Поколение'} propsOptions={filterOptions['generation']}
                                          store_value={'generation_id'}/>

                            </div>
                            <div className={s.btns}>
                                <Button
                                    size={"sm"}
                                    variant={'ghost'}
                                    onClick={resetSelectedFilters}
                                >
                                    Очистить фильтр
                                </Button>
                                <Button
                                    size={"sm"}
                                    variant={'outline'}
                                    className={'myButton'}
                                    onClick={sendData}
                                >
                                    Применить фильтр
                                </Button>
                            </div>
                        </div>
                    </form>
            }
        </>

    );
};

export default FilterMenu;