import {axiosInstance} from './base/axiosInstance'


export const axiosNotify = async () => {
    return axiosInstance.get("/notify",).then((data) => {
        return data
    })
}

export const axiosNotifyNew = async () => {
    return axiosInstance.get("/notify/new",).then((data) => {
        return data
    })
}

export const axiosNotifyAdd = async (data) => {
    return axiosInstance.post("/notify", data).then((data) => {
        return data
    })
}

export const axiosNotifyDelete = async (id) => {
    return axiosInstance.delete(`/notify?id=${id}`).then((data) => {
        return data
    })
}


export const axiosNotifyChecked= async (id) => {
    return axiosInstance.put(`/notify?id=${id}`).then((data) => {
        return data
    })
}
