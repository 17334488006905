import React from 'react';
import {Box, Spinner} from "@chakra-ui/react";

const MySpinner = ({...props}) => {
    return (
        <Box {...props} minH={'50vh'} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.600'
                size='xl'
            />
        </Box>
    );
};

export default MySpinner;