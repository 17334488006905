import React from 'react';
import {Select} from "@chakra-ui/react";
import s from './MySelect.module.scss'
import {useStore} from "../../../../store/store";

const MySelect = ({propsLabel, propsOptions, store_value}) => {
    const {selectedFilters, updateSelectedFilters} = useStore()
    const change = (e) => {
        let newValue = e.target.value
        updateSelectedFilters({
            ...selectedFilters,
            [store_value]: newValue && (newValue !== '' || newValue !== ' ') ? newValue : ''
        })
    }
    return (
        <div className={s.select}>
            <p>{propsLabel}</p>
            <Select
                data-type={propsLabel}
                color={!selectedFilters[store_value] ? 'gray' : 'unset'}
                size={'xs'}
                placeholder={propsLabel}
                value={selectedFilters[store_value]}
                onChange={change}
            >
                {
                    propsOptions.map((el) =>
                        <option
                            value={el.id}
                            key={el.id}
                        >
                            {el.value}
                        </option>
                    )
                }
            </Select>
        </div>
    );
};

export default MySelect;