import React from 'react';
import s from './Header.module.scss'
import {NavLink} from "react-router-dom";
import {Button} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import {useStore} from "../../store/store";

const Header = () => {
    const navigate = useNavigate();
    const {user,resetUser} = useStore()
    const logout=()=>{
        localStorage.removeItem('auth')
        resetUser()
        navigate('/login')
    }
    return (
        <header className={s.header}>

            <div className="container">
                <div className={s.wrapper}>
                    <div className={s.logo}>
                        <NavLink to='/'>E-MOTION AUTO</NavLink>
                    </div>
                    <div className={s.btns}>
                        <div className={s.user}>
                            <div className={s.icon}>
                                <div>{user.name[0]}</div>
                            </div>
                            <p>{user.name}</p>
                        </div>
                        <div className={s.exit}>
                            <Button onClick={logout} variant='ghost' size={'sm'}>
                                <img src="./assets/icons/logout.svg" alt="exit"/>
                                Выход
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </header>
    );
};

export default Header;