import React, {useState} from 'react';
import {
    Button, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, useToast
} from "@chakra-ui/react";
import {useStore} from "../../store/store";
import {axiosNotifyAdd} from "../../axios/notify";

const MyModal = () => {
    const {modalState, closeModal, modalContext, user} = useStore()
    const [price, setPrice] = useState('')
    const toast = useToast()
    const changePrice = (e) => {
        let value = e.target.value
        let newValue = String(value).replace(/[^0-9.]/g, '')
        setPrice(newValue)
    }


    const sendNotify = async () => {
        const dataToSend = {
            type: modalContext.type,
            name: user.name,
            car: modalContext.context
        }
        if ( modalContext.type !== 'забронировать авто'){
            dataToSend.comment = 'Пользователь предлагает '+ price
        }
        const notifyAddPromise = axiosNotifyAdd(dataToSend);
        toast.promise(notifyAddPromise, {
            success: {title: 'Успешно', description: 'Сообщение отправлено', duration: '2000'},
            error: {title: 'Ошибка', description: 'Ошибка отправки данных', duration: '2000'},
            loading: {title: 'Отправка...', description: 'Пожалуйста, подождите', duration: '2000'},
        });
        try {
            await notifyAddPromise
            setPrice('')
            closeModal()
        } catch (e) {

        } finally {


        }
    }

    return (
        <>
            <Modal size={'lg'} isCentered isOpen={modalState} onClose={closeModal}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader pb={1} textAlign={'center'}>{modalContext.title}</ModalHeader>
                    <ModalCloseButton/>

                    {
                        modalContext.type === 'забронировать авто'
                            ? <ModalBody textAlign={'center'} style={{fontSize: '14px'}}>
                                Вы действительно хотите забронировать <br/> <span style={{
                                textTransform: "capitalize",
                                textDecoration: "underline",
                                fontSize: '16px',
                                color: 'rgb(46, 55, 164)'
                            }}>{modalContext.context}</span>?
                            </ModalBody>
                            : <ModalBody textAlign={'center'} style={{fontSize: '14px'}}>
                                <div style={{
                                    textTransform: "capitalize",
                                    textDecoration: "underline",
                                    fontSize: '14px',
                                    color: 'rgb(46, 55, 164)',
                                }}>{modalContext.context}
                                </div>
                                <div style={{marginTop: '10px'}}>
                                    <Input value={price} onInput={changePrice} size={'sm'} maxW={'300px'}
                                           placeholder='Введите цену'/>

                                </div>

                            </ModalBody>

                    }
                    <ModalFooter display={'flex'} alignItems={'center'} gap={'10px'} justifyContent={'center'}>
                        <Button size={'sm'} variant='ghost' mr={3} onClick={closeModal}>
                            Нет
                        </Button>
                        {
                            modalContext.type === 'забронировать авто'
                                ? <Button
                                    onClick={sendNotify}
                                    size={'sm'}
                                    background={'rgb(46, 55, 164)'}
                                    colorScheme='blue'
                                >
                                    Забронировать
                                </Button>
                                : <Button
                                    style={{opacity:price?1:0.7, pointerEvents:price?'unset':'none'}}
                         
                                    onClick={sendNotify}
                                    size={'sm'}
                                    background={'rgb(46, 55, 164)'}
                                    colorScheme='blue'
                                >
                                    Предложить
                                </Button>
                        }

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default MyModal;