import React from 'react';
import s from './Breadcrumbs.module.scss'
import {useStore} from "../../../../../store/store";

const Breadcrumbs = ({carName}) => {
    const {menuId, resetCarPageId} = useStore()
    return (
        <div className={s.crumbs}>
            <button onClick={resetCarPageId}>{
                menuId === 1
                    ? "Коммерческий транспорт"
                    : 'Легковой транспорт'
            }</button>
            <span>/</span>
            <button >{carName}</button>
        </div>
    );
};

export default Breadcrumbs;