import React, {useState} from 'react';
import s from './Footer.module.scss'
import {Button, Checkbox, Heading, Input, Textarea, useToast} from "@chakra-ui/react";
import {ArrowUpIcon} from "@chakra-ui/icons";
import {axiosNotifyAdd} from "../../axios/notify";

const Footer = () => {
    const [name,setName]=useState('')
    const [email,setEmail]=useState('')
    const [comment,setComment]=useState('')
    const [check,setCheck]=useState(false)
    const toast = useToast()

    const sendNotify = () => {
        const dataToSend = {type:'Перезвонить',name:name,email:email,comment:comment}
        const notifyAddPromise = axiosNotifyAdd(dataToSend);
        toast.promise(notifyAddPromise, {
            success: {title: 'Успешно', description: 'Сообщение отправлено', duration: '2000'},
            error: {title: 'Ошибка', description: 'Ошибка отправки данных',duration: '2000'},
            loading: {title: 'Отправка...', description: 'Пожалуйста, подождите',duration: '2000'},
        });
        try{

        }catch (e) {

        }finally {
            setName('')
            setEmail('')
            setComment('')
            setCheck(0)
        }
    }

    return (
        <footer className={s.footer}>
            <div className={s.wrapper}>
                <div className={s.contacts}>
                    <div className={s.info}>
                        <Heading>Контакты</Heading>
                        <p>Если Вас интересует определенный автомобиль, Вы можете оставить на него заявку</p>
                        <Button variant={'ghost'}>оставить заявку <ArrowUpIcon/></Button>
                    </div>
                    <div className={s.messenger}>
                        <div>
                            <ul>
                                <li><a href="/#">Viber</a></li>
                                <li><a href="/#">Telegram</a></li>
                                <li><a href="/#">WhatsApp</a></li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li><a href="/#">E_Motion_Auto@gmail.com</a></li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className={s.form}>
                    <p>Если у Вас остались вопросы или есть предложения, Вы всегда можете связаться с нами.</p>
                    <div>
                        <Input
                            className={'br'}
                            placeholder='Имя'
                            size='sm'
                            value={name}
                            onInput={(e)=>setName(e.target.value)}
                        />
                        <Input
                            className={'br'}
                            placeholder='Email*'
                            size='sm'
                            value={email}
                            onInput={(e)=>setEmail(e.target.value)}
                        />
                        <Textarea
                            placeholder='Сообщение*'
                            value={comment}
                            onInput={(e)=>setComment(e.target.value)}
                        />
                        <div className={s.check}>
                            <Checkbox
                                size='lg'
                                colorScheme='blu'
                                isChecked={check}
                                onChange={(e)=>setCheck(e.target.checked)}

                            >
                                Даю согласие на обработку персональных данных
                            </Checkbox>
                        </div>
                        <Button
                            onClick={sendNotify}
                            className={[s.btnSend, check && name.length>2 && email.length>3 ?s.active:''].join(' ')}
                        >
                            Отправить
                        </Button>
                    </div>
                </div>
            </div>
            <div className={s.bottom}>
                <p>© E-Motion Auto</p>
                <p>Политика конфиденциальности</p>
            </div>
        </footer>
    );
};

export default Footer;