import React from 'react';
import s from './Paginator.module.scss';

const Paginator = ({ currentPage, totalCount, onPageChange }) => {
    const totalPages = Math.ceil(totalCount / 10);
    const maxVisiblePages = 6;
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2) + 1);
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
    }

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        onPageChange(page - 1);
    };

    return (
        <div className={s.paginator}>
            <div className={s.paginatorBlock}>
                {currentPage > 1 && (
                    <button onClick={() => handlePageChange(currentPage)}> {'<'} </button>
                )}
                {pages.map(page => (
                    <button
                        key={page}
                        disabled={page === currentPage + 1}
                        onClick={() => handlePageChange(page)}
                    >
                        {page}
                    </button>
                ))}
                {currentPage < totalPages - 1 && (
                    <button onClick={() => handlePageChange(currentPage + 2)}>{'>'}</button>
                )}
            </div>
            {totalCount > 0 && (
                <div className={s.paginatorTotal}>
                    Всего авто {totalCount}
                </div>
            )}
        </div>
    );
};

export default Paginator;
