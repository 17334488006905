import './App.scss';
import {Route, Routes} from 'react-router-dom';
import LoginPage from "./page/LoginPage";
import MainPage from "./page/MainPage";
import ErrorPage from "./page/ErrorPage";
import Header from "./components/header/Header";
import {useLocation} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

import {useStore} from "./store/store";
import {axiosLoginToken} from "./axios/users";
import React, {useEffect, useState} from "react";
import MySpinner from "./components/UI/mySpinner";
import MyModal from "./components/myModal/myModal";
import ModalEditCar from "./components/filter/modelEditCar/ModalEditCar";

function useLoadData() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const {setUser} = useStore()

    useEffect(() => {

        const fetchData = async () => {
            try {
                if (localStorage.getItem('auth')) {
                    let response = await axiosLoginToken();
                    response.data.authenticated = true;
                    setData(response.data);
                    setUser(response.data);
                } else {
                    setData({'name':'',role:'',token:'',authenticated:false});
                    setUser({'name':'',role:'',token:'',authenticated:false});
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, []);

    return {data, loading, error};
}

function App() {
    const location = useLocation();
    const {user, setUser} = useStore()
    const {data, loading, error} = useLoadData();
    useEffect(() => {
        if (data) {
            setUser(data);
        }
        // eslint-disable-next-line
    }, [data]);
    if (loading) {
        return <MySpinner/>
    }
    if (error) {
        return <ErrorPage error={error}/>;
    }

    return (
        <div>
            {location.pathname !== '/login' && <Header/>}
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute
                            element={MainPage}
                            isAuthenticated={user.authenticated}
                        />
                    }
                />
                <Route path="login" element={<LoginPage/>}/>
                <Route path="*" element={<ErrorPage/>}/>
            </Routes>
            <MyModal/>
            <ModalEditCar/>
        </div>
    );
}

export default App;
