import React from 'react';
import s from './TechnicalSpecifications.module.scss'
import {Heading} from "@chakra-ui/react";

const TechnicalSpecifications = ({engine,color,transmission,drive,generation,car_width,car_height,car_weight}) => {
    return (
        <div className={s.tth}>
            <div className={s.title}>
            <Heading size={'sm'}>Технические характеристики:</Heading>
            </div>
            <div className={s.info}>
                <div>
                    <p>Двигатель:</p>
                    <p>{engine}</p>
                </div>
                <div>
                    <p>Цвет автомобиля:</p>
                    <p>{color}</p>
                </div>
                <div>
                    <p>Коробка передач:</p>
                    <p>{transmission}</p>
                </div>
                <div>
                    <p>Привод:</p>
                    <p>{drive}</p>
                </div>
                <div>
                    <p>Размерность:</p>
                    <p>Высота - {car_height} м</p>
                    <p>Ширина - {car_width} м</p>
                    <p>Вес – {car_weight} кг </p>

                </div>

            </div>
        </div>
    );
};

export default TechnicalSpecifications;