import React from 'react';
import s from './MySelectAddCar.module.scss'
import {Select} from "@chakra-ui/react";
import {useStore} from "../../../../../../store/store";

const MySelectAddCar = ({placeholder, options, stateName}) => {
    const {addCarData, setAddCarData} = useStore()
    return (
        <div className={s.select}>
            <label>
                <h6>{placeholder}
                    { stateName !=='generation_id' && <span>*</span>}
                </h6>

                <Select
                    data-type={placeholder}
                    value={addCarData['stateName']}
                    size={'xs'}
                    required={stateName !== 'generation_id'}
                    placeholder={' '}
                    onChange={(e) => setAddCarData({...addCarData, [stateName]: e.target.value})}
                >
                    {
                        options && options.map((el) =>
                            <option key={el.id} value={el.id}>{el.value}</option>
                        )
                    }
                </Select>
            </label>
        </div>
    );
};

export default MySelectAddCar;