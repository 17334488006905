import React from 'react';
import s from './NoPhotoBlock.module.scss'
import {Box, Heading} from "@chakra-ui/react";
import {API_URL} from "../../../../../axios/base/axiosInstance";

const NoPhotoBlock = () => {
    return (
        <Box className={s.noPhoto}   background={`url("${API_URL}static/images/cars/nophoto.jpg")`} >
            <Heading>Нет фото</Heading>
        </Box>
    );
};

export default NoPhotoBlock;