import React, {useState} from 'react';
import s from './MyInputCheckboxAddCar.module.scss'
import {Checkbox} from "@chakra-ui/react";
import {useStore} from "../../../../../../store/store";

const MyInputCheckboxAddCar = ({data}) => {
    const [value, setValue] = useState('')
    const {addCarData, setAddCarData} = useStore()

    const change = (e) => {
        setValue(e.target.checked)

        let copy = {...addCarData['options'], [data.id]: e.target.checked}
        setAddCarData({...addCarData, options: copy})


    }

    return (
        <div className={s.input}>
            <Checkbox
                style={{color: value ? '#0F1028' : '#898DB9'}}
                value={value}
                onChange={(e) => change(e)}

            >
                {data.value}
            </Checkbox>
        </div>
    );
};

export default MyInputCheckboxAddCar;