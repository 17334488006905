import React, {useRef} from 'react';
import s from './CarListItem.module.scss'
import {Heading, Image, useToast} from '@chakra-ui/react'
import {generateImagesPass} from "../../../../utils/utils";
import {axiosCarsDelete} from "../../../../axios/cars";
import {useStore} from "../../../../store/store";


const CarListItem = ({role_id,car_data, ...props}) => {
    const toast = useToast()
    const ref =useRef()
    const {openModalEdit}=useStore()

    const editMethod = (e, car_id) => {
        e.stopPropagation()
        openModalEdit(car_id)


    }
    const deleteMethod =async (e) => {
        e.stopPropagation()
        let promiseDelete = axiosCarsDelete(car_data.id)
        toast.promise(promiseDelete, {
            success: {title: 'Успех', description: 'Уведомление удалено', duration: '1000'},
            error: {title: 'Ошибка', description: 'Ошибка удаления', duration: '1000'},
            loading: {title: 'Удаление...', description: 'Пожалуйста, подождите'},
        });

        try {
            await promiseDelete
            ref.current.style.display='none'
        }catch(err){}
    }
    return (
<>
    { car_data &&
        <li ref={ref} {...props} className={[s.item, 'br'].join(' ')}>
            <div>
                <div className={s.car}>
                    <div className={s.img}>
                        <Image
                            src={generateImagesPass(car_data)}
                            alt='car'
                        />
                    </div>
                    <div className={s.info}>
                        <Heading size={'sm'}>{car_data['brand']['value']} {car_data['model']['value']}, {car_data['year']}</Heading>
                        <div>
                            <p>Топливо:</p>
                            <p>{car_data['fuel_type']['value']}</p>
                        </div>
                        <div>
                            <p>Пробег:</p>
                            <p>{car_data['mileage']} км</p>
                        </div>
                        <div>
                            <p>Двигатель:</p>
                            <p>{car_data['engine']['value']}</p>
                        </div>
                        <div>
                            <p style={{textTransform:'capitalize'}}>{car_data['car_type']['value']}</p>
                        </div>
                    </div>
                </div>
                <div className={s.column}>
                    <div className={s.price}>
                        <div>
                            <p>Цена:</p>
                            <p>€{car_data['price']}</p>
                        </div>
                        <div>
                            <p>Партия:</p>
                            <p>{car_data['car_lot']} шт</p>
                        </div>
                    </div>
                    {
                        role_id === 1 &&
                        <div className={s.control}>
                            <button onClick={(e)=>editMethod(e,car_data.id)}>Редактировать</button>
                            <button onClick={deleteMethod}>Удалить</button>
                        </div>
                    }

                </div>

            </div>
        </li>
    }
</>
    );
};

export default CarListItem;