import {API_URL} from "../axios/base/axiosInstance";

export const formatDate=(dateSting)=>{
    const date = new Date(dateSting);
    const months = [
        "января", "февраля", "марта", "апреля", "мая", "июня",
        "июля", "августа", "сентября", "октября", "ноября", "декабря"
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
}

export const formatDate2=(dateSting)=>{
    let date = new Date(dateSting);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    return `${day}-${month}-${year}`
}

export const generateImagesPass=(data)=>{
    if (!data.car_photos[0]){
        return API_URL + "static/images/cars/nophoto.jpg"
    }
    if (!data.car_photos[0]?.file_path && data.car_photos[0]?.file_name){
        return API_URL + "static/images/cars/nophoto.jpg"
    }
    return API_URL + data.car_photos[0]?.file_path + data.car_photos[0]?.file_name
}