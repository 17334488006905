import {axiosInstance} from './base/axiosInstance'


export const axiosGetOptionsAddCar = async (data) => {
    return axiosInstance.post("/filters/add_car_options", data).then((data) => {
        return data
    })
}

export const axiosGetFilters = async (data) => {
    return axiosInstance.post("/filters", data).then((data) => {
        return data
    })
}