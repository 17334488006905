import React from 'react';
import s from './CarList.module.scss'
import CarListItem from "./carlistItem/CarListItem";
import {useStore} from "../../../store/store";
import MySpinner from "../../UI/mySpinner";
import {Box, Heading} from "@chakra-ui/react";

const CarList = ({type, cars, loading}) => {
    const {setCarPageId,user} = useStore()

    const click = (id) => {
        setCarPageId(id)
    }

    return (
        <div className={s.carList}>
            {
                loading
                    ? < MySpinner/>
                    : cars && cars.length !== 0
                        ? <ul>
                            {
                                cars.map((car) =>
                                    <CarListItem
                                        role_id={user.role}
                                        car_data={car}
                                        key={car.id}
                                        type={type}
                                        onClick={() => click(car.id)}
                                    />
                                )
                            }
                        </ul>
                        : cars !== null

                            ? <Box className={s.carsNotFound}>
                                <Heading
                                    size={'sm'}
                                >
                                    Автомобили не найдены
                                </Heading>
                            </Box>
                            : ''
            }



        </div>
    );
};

export default CarList;