import React from 'react';
import s from './MyInputNumberAddCar.module.scss'
import {Input} from "@chakra-ui/react";
import {useStore} from "../../../../../../store/store";

const MyInputNumberAddCar = ({placeholder, stateName}) => {
    const {addCarData, setAddCarData} = useStore()

    const handleInputChange = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9]/g, '');
        setAddCarData({ ...addCarData, [stateName]: numericValue });
    };
    return (
        <div className={s.input}>
            <label>
                <h6>{placeholder} <span>*</span></h6>


                <Input
                    value={addCarData[stateName]}
                    data-type={placeholder}
                    required={true}
                    size={'xs'}
                    onInput={handleInputChange}
                    placeholder={' '}
                />
            </label>
        </div>
    );
};

export default MyInputNumberAddCar;