import React from 'react';
import s from './OptionsBlock.module.scss'
import MyInputCheckboxAddCar from "../inputs/MyInputCheckboxAddCar";

const OptionsBlock = ({carOptions}) => {


    return (
        <div className={s.option}>
            {
                carOptions && carOptions.map((el)=>
                    <MyInputCheckboxAddCar key={el.id} data={el} />
                )
            }

        </div>
    );
};

export default OptionsBlock;