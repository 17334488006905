import React, {useEffect, useState} from 'react';
import s from './Filter.module.scss'
import {Button, Heading} from "@chakra-ui/react";
import FilterMenu from "./filterMenu/FilterMenu";
import CarList from "./carList/CarList";
import CarPage from "./carPage/carPage";
import {useStore} from "../../store/store";
import {axiosCarsAll} from "../../axios/cars";
import Paginator from "./paginator/Paginator";


const Filter = ({type}) => {
    const {
        carPageId,
        updateMenuId,
        resetCarPageId,
        resetSelectedFilters,
        user,
        selectedFilters,
    } = useStore()
    const [cars, setCars] = useState(null);
    const [totalCount, setTotalCount] = React.useState(null)
    const [currentPage, setCurrentPage] = React.useState(0);
    const [loading, setLoading] = useState(false);
    const click = () => {
        resetSelectedFilters()
        resetCarPageId()
        updateMenuId(5)
    }

    useEffect(() => {
        setLoading(true)
        let filters = {"car_type_id": type === 'commerce' ? 2 : 1}
        axiosCarsAll(filters).then((data) => {
            setCars(data.data.cars)
            setTotalCount(data.data.count)
        }).finally(() => setLoading(false))
        // eslint-disable-next-line
    }, []);

    const showActualCar = () => {
        setLoading(true)
        let filters = {"car_type_id": type === 'commerce' ? 2 : 1}
        filters = {...filters, ...selectedFilters}
        let filteredFilters = Object.keys(filters).reduce((acc, key) => {
            if (filters[key]) {
                acc[key] = filters[key];
            }
            return acc;
        }, {});
        axiosCarsAll(filteredFilters).then((data) => {
            setCars(data.data.cars)
            setTotalCount(data.data.count)
        }).finally(() => setLoading(false))
    }


    const handlePageChange = (page) => {
        let filters = {"car_type_id": type === 'commerce' ? 2 : 1}
        filters = {...filters, ...selectedFilters}
        let filteredFilters = Object.keys(filters).reduce((acc, key) => {
            if (filters[key]) {
                acc[key] = filters[key];
            }
            return acc;
        }, {});
        axiosCarsAll({...filteredFilters,page:page}).then((data) => {
            setCars(data.data.cars)
            setTotalCount(data.data.count)
        })
        setCurrentPage(page)
    };

    return (
        <>
            {
                !carPageId
                    ? <div className={s.filter}>
                        <div className={s.title}>
                            <Heading>
                                {
                                    type === 'commerce' ? 'Коммерческий транспорт' : 'Легковой транспорт'
                                }
                            </Heading>
                            {
                                user.role === 1 &&
                                <Button onClick={click} variant={'outline'} size={'sm'} className={'myButton'}>Добавть
                                    авто</Button>
                            }
                        </div>
                        <FilterMenu showActualCar={showActualCar} type={type}/>
                        <CarList cars={cars} type={type} loading={loading}/>
                        <Paginator
                            currentPage={currentPage}
                            totalCount={totalCount}
                            onPageChange={handlePageChange}
                        />

                    </div>
                    : <CarPage/>
            }

        </>

    );
};

export default Filter;