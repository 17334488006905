import React from 'react';
import SideBar from "../components/main/sidebar/SideBar";
import s from './MainPage.module.scss'
import Body from "../components/main/body/Body";

const MainPage = () => {
    document.title = "E-MOTION AUTO";
    return (
        <main className={s.main}>
            <div className={'container'}>
                <div className={s.wrapper}>
                    <SideBar/>
                    <Body/>
                </div>
            </div>
        </main>

    );
};

export default MainPage;