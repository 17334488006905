import React from 'react';
import Filter from "../../../filter/Filter";
import Footer from "../../../footer/Footer";

const CommercialVehicles = () => {
    return (
        <div>
            <Filter type={'commerce'}/>
            <Footer/>
        </div>
    );
};

export default CommercialVehicles;