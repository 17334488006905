import React from 'react';
import s from './CommentBlock.module.scss'
import {Heading} from "@chakra-ui/react";

const CommentBlock = ({comment}) => {
    return (
        <div className={s.comment}>
            <div className={s.title}>
                <Heading size={'sm'}>Дополнительные комментарии:</Heading>
            </div>
            <div className={s.text}>
                <p>{comment}</p>
            </div>
        </div>
    );
};

export default CommentBlock;