import React from 'react';
import s from './Body.module.scss'
import {useStore} from "../../../store/store";
import CommercialVehicles from "./commercialVehicles/CommercialVehicles";
import PassengerVehicle from "./passengerVehicle/PassengerVehicle";
import Notifications from "./notifications/Notifications";
import News from "./news/News";
import AddCar from "./addCar/AddCar";
import AddUser from "./addUser/AddUser";

const Body = () => {
    const {menuId}=useStore()
    return (
        <div className={s.body}>
            {
                menuId ===1 && <CommercialVehicles/>
            }
            {
                menuId ===2 && <PassengerVehicle/>
            }
            {
                menuId ===3 && <Notifications/>
            }
            {
                menuId ===4 && <News/>
            }
            {
                menuId ===5 && <AddCar/>
            }
            {
                menuId ===6 && <AddUser/>
            }
        </div>
    );
};

export default Body;