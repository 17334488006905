import React from 'react';
import s from './Reserve.module.scss'
import {Button} from "@chakra-ui/react";
import {formatDate2} from "../../../../../utils/utils";
import {useStore} from "../../../../../store/store";

const Reserve = ({price, count, fuel_type, mileage, year, vin, country_of_origin, date_of_registration, car}) => {
    const { openModal, setModalContext, resetModalContext} = useStore()
    return (
        <div className={s.reserve}>
            <div className={s.top}>
                <div className={s.price}>
                    €{price}
                </div>
                <div className={s.count}>
                    <p>Партия: <span>{count} шт</span></p>
                </div>
            </div>
            <div className={s.info}>
                <div>
                    <p>Топливо:</p>
                    <p>{fuel_type}</p>
                </div>
                <div>
                    <p>Пробег:</p>
                    <p>{mileage} км</p>
                </div>
                <div>
                    <p>Год выпуска:</p>
                    <p>{year}</p>
                </div>
                <div>
                    <p>VIN-номер:</p>
                    <p>{vin}</p>
                </div>
                <div>
                    <p>Страна происхождения:</p>
                    <p>{country_of_origin}</p>
                </div>
                <div>
                    <p>Дата регистрации:</p>
                    <p>{formatDate2(date_of_registration)}</p>
                </div>
            </div>
            <div className={s.btns}>
                <Button
                    size={'sm'}
                    onClick={() => {
                        resetModalContext()
                        openModal()
                        setModalContext({
                            title: "Забронировать авто",
                            type: "забронировать авто",
                            context: `${car['brand']['value']} ${car['model']['value']}  ${car['year']}, VIN-нормер ${vin}`
                        })
                    }}
                >
                    Забронировать
                </Button>
                <Button
                    size={'sm'}
                    variant={'outline'}
                    onClick={() => {
                        resetModalContext()
                        openModal()
                        setModalContext({
                            title: "Предложить свою цену за авто",
                            type: "Предложить свою цену за авто",
                            context: `${car['brand']['value']} ${car['model']['value']}  ${car['year']}, VIN-нормер ${vin}`
                        })
                    }}
                >
                    Предложить свою цену
                </Button>
            </div>
        </div>
    );
};

export default Reserve;