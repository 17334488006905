import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

export const useStore = create(devtools(
    (set) => ({
        menuId: 1,
        updateMenuId: (newMenuId) => set({menuId: newMenuId}),
        selectedFilters: {
            year_from: '',
            year_to: '',
            price_from: '',
            price_to: '',
            mileage_to: '',
            mileage_from: '',
            brand_id: '',
            model_id: '',
            car_condition_id: '',
            transmission_id: '',
            engine_id: '',
            car_body_id: '',
            fuel_type_id: '',
            color_id: '',
            generation_id: '',
            page: 0
        },
        updateSelectedFilters: (data) => set({selectedFilters: data}),
        resetSelectedFilters: () => set({
            selectedFilters: {
                year_from: '',
                year_to: '',
                price_from: '',
                price_to: '',
                mileage_to: '',
                mileage_from: '',
                brand_id: '',
                model_id: '',
                car_condition_id: '',
                transmission_id: '',
                engine_id: '',
                car_body_id: '',
                fuel_type_id: '',
                color_id: '',
                generation_id: '',
                page: 0
            }
        }),
        resetModel: () => () => set((state) => ({selectedFilters: {...state.selectedFilters, model_id: ''}})),
        countNotification: 0,
        decrementPopulation: () => set((state) => ({countNotification: state.countNotification - 1})),
        setNotification: (data) => set({countNotification: data}),


        carPageId: null,
        setCarPageId: (data) => set({carPageId: data}),
        resetCarPageId: () => set({carPageId: null}),


        user: {name: '', role: '', token: '', authenticated: false},
        setUser: (data) => set({user: data}),
        resetUser: () => set({user: {name: '', role: '', token: '', authenticated: false}}),

        modalState: false,
        modalContext: {title: "", type: "", context: ''},
        openModal: () => set({modalState: true}),
        closeModal: () => set({modalState: false}),
        setModalContext: (data) => set({modalContext: data}),
        resetModalContext: () => set({modalContext: {title: "", type: ""}}),

        addCarData: {
            "car_type_id": '',
            "brand_id": '',
            "models_id": '',
            "year": '',
            "fuel_type_id": '',
            "country_of_origin_id": '',
            "mileage": "",
            "vin": "",
            "date_of_registration": "",
            "engine_id": "",
            "transmission_id": "",
            "drive_type_id": "",
            "color_id": "",
            "generation_id": "",
            "car_height": "",
            "car_width": "",
            "car_weight": "",
            "price": "",
            "additional_comments": "",
            "car_lot": "",
            "car_body_id": "",
            "count_seat": "",
            "car_condition_id": "",
            "options": {}
        },
        setAddCarData: (data) => set({addCarData: data}),
        resetAddCarData: () => set({
            addCarData: {
                "car_type_id": '',
                "brand_id": '',
                "models_id": '',
                "year": '',
                "fuel_type_id": '',
                "country_of_origin_id": '',
                "mileage": "",
                "vin": "",
                "date_of_registration": "",
                "engine_id": "",
                "transmission_id": "",
                "drive_type_id": "",
                "color_id": "",
                "generation_id": "",
                "car_height": "",
                "car_width": "",
                "car_weight": "",
                "price": "",
                "additional_comments": "",
                "car_lot": "",
                "car_body_id": "",
                "count_seat": "",
                "car_condition_id": "",
                "options": {}
            }
        }),
        editModalState: {state: false, car_id: null},
        openModalEdit: (data) => set((state) => ({editModalState: {...state.editModalState,state:true, car_id: data}})),
        closeModalEdit: (data) => set((state) => ({editModalState: {...state.editModalState,state:false, car_id: null}})),
    })))