import React, {useState} from 'react';
import s from './AddCar.module.scss'
import {Heading} from "@chakra-ui/react";
import FormAddCar from "./formAddCar/FormAddCar";

const AddCar = () => {
    const [key,setKey]=useState(0)
    const reloadPage=()=>{
        setKey(key+1)
    }
    return (
        <div className={s.addCar}>
            <Heading fontWeight={'normal'} size={'xl'}>Добавить авто</Heading>
            <FormAddCar key={key} reloadPage={reloadPage} />


        </div>
    );
};

export default AddCar;