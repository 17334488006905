import React from 'react';
import s from './Notifications.module.scss'
import {Heading} from "@chakra-ui/react";
import NotifyList from "./notifyList/NotifyList";

const Notifications = () => {
    return (
        <div className={s.notify}>
            <Heading fontWeight={'normal'} size={'xl'}>Уведомления</Heading>
            <NotifyList/>

        </div>
    );
};

export default Notifications;