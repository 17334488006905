import React, {useEffect, useRef, useState} from 'react';
import s from './FormAddCar.module.scss'
import {Button, Heading, useToast} from "@chakra-ui/react";
import MySelectAddCar from "./inputs/MySelectAddCar";
import MyInputNumberAddCar from "./inputs/MyInputNumberAddCar";
import MyInputTextAddCar from "./inputs/MyInputTextAddCar";
import MyInputDateAddCar from "./inputs/MyInputDateAddCar";
import MyTextAreaAddCar from "./inputs/MyTextAreaAddCar";
import ImageUploader from "./inputs/ImageUploader";
import OptionsBlock from "./optionsBlock/OptionsBlock";
import {axiosGetOptionsAddCar} from "../../../../../axios/filters";
import {useStore} from "../../../../../store/store";
import {axiosCarsAdd} from "../../../../../axios/cars";

const FormAddCar = ({reloadPage}) => {
    const [filtersOptions, setFiltersOptions] = useState({})
    const {addCarData, resetAddCarData} = useStore()
    const refFile=useRef()
    const toast = useToast()
    const sendData =async (e) => {
        e.preventDefault()
        let formData = new FormData()
        for (const key in addCarData) {
            if (key ==='options'){
                let json = JSON.stringify(addCarData[key])
                formData.append(key, json)
            }else{
                formData.append(key, addCarData[key])
            }

        }
        for (const file of refFile.current.files) {
            formData.append('photos', file);
        }


        let promiseSendData =  axiosCarsAdd(formData)
        toast.promise(promiseSendData, {
            success: {title: 'Успех', description: 'Автомобиль добавлен', duration: '3000'},
            error: {title: 'Ошибка', description: 'Ошибка добавления автомобиля', duration: '5000'},
            loading: {title: 'Отправка...', description: 'Пожалуйста, подождите'},
        });

        try{
            await promiseSendData
            resetAddCarData()
            reloadPage()
        }catch (e) {

        }
    }

    useEffect(() => {
        axiosGetOptionsAddCar({}).then((data) => {
            setFiltersOptions(data.data)
        })
    }, []);


    const changeForm = (e) => {
        if (e.target.hasAttribute('data-type')) {
            if (e.target.getAttribute('data-type') === 'Марка') {
                axiosGetOptionsAddCar({"brand_id": e.target.value}).then((data) => {
                    setFiltersOptions(data.data)
                })
            }
            if (e.target.getAttribute('data-type') === 'Модель') {
                axiosGetOptionsAddCar({"models_id": e.target.value}).then((data) => {
                    setFiltersOptions(data.data)
                })
            }
        }
    }

    return (
        <form onSubmit={sendData} className={s.form} onChange={changeForm}>
            <MySelectAddCar options={filtersOptions['car_type']} stateName={'car_type_id'}
                            placeholder={'Вид транспорт'}/>
            <MySelectAddCar options={filtersOptions['car_condition']} stateName={'car_condition_id'}
                            placeholder={'Состояние авто'}/>
            <MyInputNumberAddCar placeholder={'Партия'} stateName={'car_lot'}/>
            <Heading size={'md'}>Основные характеристики</Heading>
            <MySelectAddCar options={filtersOptions['brands']} stateName={'brand_id'} placeholder={'Марка'}/>
            <MySelectAddCar options={filtersOptions['models']} stateName={'models_id'} placeholder={'Модель'}/>
            <MyInputNumberAddCar stateName={'year'} placeholder={'Год выпуска'}/>
            <MySelectAddCar options={filtersOptions.fuel_type} stateName={'fuel_type_id'} placeholder={'Топливо'}/>
            <MySelectAddCar options={filtersOptions.country_of_origin} stateName={'country_of_origin_id'}
                            placeholder={'Страна происхождения'}/>
            <MyInputNumberAddCar stateName={'mileage'} placeholder={'Пробег'}/>
            <MyInputTextAddCar stateName={'vin'} placeholder={'VIN-номер'}/>
            <MyInputDateAddCar stateName={'date_of_registration'} placeholder={'Дата регистрации'}/>
            <Heading size={'md'}>Технические характеристики:</Heading>
            <MySelectAddCar stateName={'engine_id'} options={filtersOptions['engine']} placeholder={'Двигатель'}/>
            <MySelectAddCar stateName={'transmission_id'} options={filtersOptions['transmission']}
                            placeholder={'Коробка передач'}/>
            <MySelectAddCar stateName={'drive_type_id'} options={filtersOptions['drive_type']} placeholder={'Привод'}/>
            <MySelectAddCar stateName={'color_id'} options={filtersOptions['color']} placeholder={'Цвет автомобиля'}/>
            <MySelectAddCar stateName={'car_body_id'} options={filtersOptions['car_body']} placeholder={'Кузов'}/>
            <MySelectAddCar stateName={'generation_id'} options={filtersOptions['generation']}
                            placeholder={'Поколение'}/>

            <Heading size={'md'}>Описание комплектации:</Heading>

            <OptionsBlock carOptions={filtersOptions.options}/>

            <Heading size={'md'}>Разместите фото:</Heading>
            <ImageUploader propsRefFile={refFile}/>

            <MyInputNumberAddCar stateName={'price'} placeholder={'Цена'}/>
            <MyInputNumberAddCar stateName={'car_height'} placeholder={'Длинна авто'}/>
            <MyInputNumberAddCar stateName={'car_width'} placeholder={'Ширина авто'}/>
            <MyInputNumberAddCar stateName={'car_weight'} placeholder={'Вес авто'}/>
            <MyInputNumberAddCar stateName={'count_seat'} placeholder={'Количество мест'}/>
            <Heading size={'md'}>Дополнительные комментарии:</Heading>
            <MyTextAreaAddCar stateName={'additional_comments'} placeholder={'Оставьте комментарий'}/>

            <Button
                variant={'outline'}
                className={['myButton', s.btnSend].join(' ')}
                type={"submit"}

            >
                Разместить обьявление
            </Button>
        </form>
    );
};

export default FormAddCar;