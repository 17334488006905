import React from 'react';
import s from './MyInputTextAddCar.module.scss'
import {Input} from "@chakra-ui/react";
import {useStore} from "../../../../../../store/store";

const MyInputTextAddCar = ({placeholder,stateName}) => {
    const {addCarData, setAddCarData} = useStore()
    return (
        <div className={s.input}>
            <label>
                <h6>{placeholder} <span>*</span></h6>


                <Input
                required={true}
                size={'xs'}
                placeholder={' '}
                value={addCarData[stateName]}
                onInput={(e)=>setAddCarData({...addCarData,[stateName]:e.target.value})}
            />
            </label>
        </div>
    );
};

export default MyInputTextAddCar;