import {axiosInstance} from './base/axiosInstance'


export const axiosLogin = async (data) => {
    return axiosInstance.post("/users/login", data).then((data) => {
        return data
    })
}

export const axiosAddUser = async (data) => {
    return axiosInstance.post("/users", data).then((data) => {
        return data
    })
}

export const axiosLoginToken = async () => {
    let token = localStorage.getItem('auth')
    return axiosInstance.get(`/users/token?token=${token}`).then((data) => {
        return data
    })
}
