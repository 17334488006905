import React, {useState} from 'react';
import s from './SliderBlock.module.scss';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import {FreeMode, Navigation, Thumbs} from 'swiper/modules';
import {API_URL} from "../../../../../axios/base/axiosInstance";

const SliderBlock = ({photos}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div className={s.sliderBlock}>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                spaceBetween={10}
                navigation={true}
                thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : undefined}}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
            >
                {
                    photos.map((el) =>
                        <SwiperSlide key={el.id}>
                            <img src={API_URL + el.file_path + el.file_name} alt="car"/>
                        </SwiperSlide>
                    )
                }
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={6}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className={s.swiper2}
            >
                {
                    photos.map((el) =>
                        <SwiperSlide key={el.id}>
                            <img src={API_URL + el.file_path + el.file_name} alt="car"/>
                        </SwiperSlide>
                    )
                }
            </Swiper>
        </div>
    );
};

export default SliderBlock;
