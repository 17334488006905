import React from 'react';
import {Textarea} from "@chakra-ui/react";
import s from './MyTextAreaAddCar.module.scss'
import {useStore} from "../../../../../../store/store";

const MyTextAreaAddCar = ({placeholder, stateName}) => {
    const {addCarData, setAddCarData} = useStore()
    return (
        <div className={s.textarea}>

            <Textarea
                size={'xs'}
                placeholder={placeholder}
                value={addCarData[stateName]}
                onInput={(e)=>setAddCarData({...addCarData,[stateName]:e.target.value})}
            />

        </div>
    );
};

export default MyTextAreaAddCar;