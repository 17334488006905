import React from 'react';
import s from './ConfigurationDescription.module.scss'
import {Heading} from "@chakra-ui/react";
import {CheckIcon} from "@chakra-ui/icons";

const ConfigurationDescription = ({options}) => {
    return (
        <div className={s.conf}>
            <div className={s.title}>
                <Heading size={'sm'}>Описание комплектации:</Heading>
            </div>
            <div className={s.info}>
                {
                    options.length
                    ? options.map((el) =>
                            <div key={el.options.id}>
                                <CheckIcon/>
                                <p>{el.options.value}</p>
                            </div>
                        )
                        : ''
                }
            </div>
        </div>
    );
};

export default ConfigurationDescription;