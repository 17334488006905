import React, {useState} from 'react';
import s from './AddUserForm.module.scss'
import {Button, Input, useToast} from "@chakra-ui/react";
import {axiosAddUser} from "../../../../../axios/users";

const AddUserForm = () => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const toast = useToast()
    const onSubmit = async (e) => {
        e.preventDefault()
       try{
           await axiosAddUser({login: login, password: password, name: name})
           toast({
               title: 'Пользователь Создан',
               description: `Логин: ${login}\nПароль: ${password}`,
               status: 'success',
               duration: 9000,
               isClosable: true,
           })
           setLogin('')
           setPassword('')
           setName('')
       }
       catch (e) {
           toast({
               title: 'Ошибка создания',
               description: e.response.data.detail,
               status: 'error',
               duration: 9000,
               isClosable: true,
           })
       }
    }

    return (
        <form onSubmit={onSubmit} className={s.form}>
            <label>
                <div>Логин</div>
                <Input size={'sm'} value={login} onInput={(e) => setLogin(e.target.value)} required={true}/>
            </label>
            <label>
                <div>Пароль</div>
                <Input size={'sm'} value={password} onInput={(e) => setPassword(e.target.value)} required={true}/>
            </label>
            <label>
                <div>Имя</div>
                <Input size={'sm'} value={name} onInput={(e) => setName(e.target.value)} required={true}/>
            </label>
            <Button style={{
                opacity: login.length && password.length && name.length ? 1 : 0.5,
                pointerEvents: login.length && password.length && name.length ? 'unset' : 'none',
            }} type={'submit'} className={'myButton'}>Добавить</Button>
        </form>
    );
};

export default AddUserForm;