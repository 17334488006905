import {axiosInstance} from './base/axiosInstance'


export const axiosCarsAll = async (data) => {
    return axiosInstance.post("/cars/all", data).then((data) => {
        return data
    })
}

export const axiosCarsAdd = async (data) => {
    return axiosInstance.post("/cars/add", data).then((data) => {
        return data
    })
}

export const axiosCarsOne = async (id) => {
    return axiosInstance.get(`/cars/one?car_id=${id}`,).then((data) => {
        return data
    })
}
export const axiosCarsDelete = async (id) => {
    return axiosInstance.delete(`/cars/delete?car_id=${id}`,).then((data) => {
        return data
    })
}


export const axiosCarUpdate = async (data) => {
    return axiosInstance.put(`/cars/edit`,data).then((data) => {
        return data
    })
}