import React from 'react';
import s from "./InputFromTo.module.scss";
import {Input} from "@chakra-ui/react";
import {useStore} from "../../../../store/store";


const InputFromTo = ({propsLabel, type}) => {
    const {selectedFilters, updateSelectedFilters} = useStore()


    const setFrom = (e) => {
        let newValue = e.target.value

        if (!newValue) {
            return updateSelectedFilters({...selectedFilters, [`${type}_from`]: newValue})
        }
        let value = String(newValue).replace(/[^0-9.]/g, '')
        let formattedValue = new Intl.NumberFormat('ru-RU').format(value.replace(/\B(?=(\d{3})+(?!\d))/g, ''));
        updateSelectedFilters({...selectedFilters, [`${type}_from`]: formattedValue})


    }
    const setTo = (e) => {
        let newValue = e.target.value
        if (!newValue) {
            return updateSelectedFilters({...selectedFilters, [`${type}_to`]: newValue})
        }
        let value = String(newValue).replace(/[^0-9.]/g, '')
        let formattedValue = new Intl.NumberFormat('ru-RU').format(value.replace(/\B(?=(\d{3})+(?!\d))/g, ''));
        updateSelectedFilters({...selectedFilters, [`${type}_to`]: formattedValue})

    }

    return (
        <>
            {type === 'year' &&
                <div className={s.fromTo}>
                    <p>{propsLabel}</p>
                    <div>
                        <Input
                            placeholder={'От'}
                            size={'xs'}
                            value={selectedFilters.year_from}
                            onChange={setFrom}
                        />
                        <Input
                            placeholder={'До'}
                            size={'xs'}
                            value={selectedFilters.year_to}
                            onChange={setTo}
                        />
                    </div>
                </div>
            }
            {
                type === 'price' &&
                <div className={s.fromTo}>
                    <p>{propsLabel}</p>
                    <div>
                        <Input
                            placeholder={'От'}
                            size={'xs'}
                            value={selectedFilters.price_from}
                            onChange={setFrom}
                        />
                        <Input
                            placeholder={'До'}
                            size={'xs'}
                            value={selectedFilters.price_to}
                            onChange={setTo}
                        />
                    </div>
                </div>
            }
            {
                type === 'mileage' &&
                <div className={s.fromTo}>
                    <p>{propsLabel}</p>
                    <div>
                        <Input
                            placeholder={'От'}
                            size={'xs'}
                            value={selectedFilters.mileage_from}
                            onChange={setFrom}
                        />
                        <Input
                            placeholder={'До'}
                            size={'xs'}
                            value={selectedFilters.mileage_to}
                            onChange={setTo}
                        />
                    </div>
                </div>
            }
        </>
    )

};

export default InputFromTo;