import React, {useEffect, useState} from 'react';
import s from './carPage.module.scss'
import Breadcrumbs from "./components/breadcrumbs/Breadcrumbs";
import {Button, Heading} from "@chakra-ui/react";
import SliderBlock from "./components/sliderBlock/SliderBlock";
import Reserve from "./components/reserve/Reserve";
import TechnicalSpecifications from "./components/technicalSpecifications/TechnicalSpecifications";
import CommentBlock from "./components/commentsBlock/CommentBlock";
import ConfigurationDescription from "./components/configurationDescription/ConfigurationDescription";
import {useStore} from "../../../store/store";
import MySpinner from "../../UI/mySpinner";
import {axiosCarsOne} from "../../../axios/cars";
import NoPhotoBlock from "./components/noPhotoBlock/NoPhotoBlock";

const CarPage = () => {

    const {carPageId,user, openModalEdit} = useStore()
    const [loading, setLoading] = useState(false)
    const [carData, setCarData] = useState(null)
    useEffect(() => {
        setLoading(true)
        axiosCarsOne(carPageId).then((data) => {
            setCarData(data.data)
        }).finally(() => setLoading(false))
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading
                    ? <MySpinner/>
                    : carData
                        ? <div className={s.carPage}>
                            <Breadcrumbs carName={`${carData['brand']['value']} ${carData['model']['value']}`}/>
                            <div className={s.title}>
                                <Heading size={'lg'}>{carData['brand']['value']} {carData['model']['value']}</Heading>
                                <div>
                                    {/*<Button variant={'ghost'} size={'xs'}>Скачать предложение </Button>*/}
                                    {
                                        user.role ===1 &&
                                        <Button
                                            variant={'outline'}
                                            size={'xs'}
                                            className={'myButton'}
                                            onClick={()=>{openModalEdit(carPageId)}}
                                        >
                                            Редактировать
                                        </Button>
                                    }

                                </div>
                            </div>
                            <div className={s.column}>
                                {
                                    carData['car_photos'].length
                                        ? <SliderBlock photos={carData['car_photos']}/>
                                        : <NoPhotoBlock/>
                                }
                                <Reserve
                                    car={carData}
                                    price={carData['price']}
                                    count={carData['car_lot']}
                                    fuel_type={carData['fuel_type']['value']}
                                    mileage={carData['mileage']}
                                    year={carData['year']}
                                    vin={carData['vin']}
                                    country_of_origin={carData['country_of_origin']['value']}
                                    date_of_registration={carData['date_of_registration']}

                                />
                            </div>
                            <div className={s.column}>
                                <div>
                                    <TechnicalSpecifications
                                        engine={carData['engine']['value']}
                                        color={carData['color']['value']}
                                        transmission={carData['transmission']['value']}
                                        drive={carData['drive_type']['value']}
                                        generation={carData['generation']?.value}
                                        car_width={carData['car_width']}
                                        car_height={carData['car_height']}
                                        car_weight={carData['car_weight']}
                                    />
                                    <CommentBlock
                                        comment={carData['additional_comments']}
                                    />
                                </div>
                                <ConfigurationDescription
                                options={carData['car_options']}
                                />
                            </div>
                        </div>
                        : ''
            }


        </>
    );
};

export default CarPage;