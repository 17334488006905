import React, {useState} from 'react';
import s from './LoginPage.module.scss'
import {Button, Heading, Input, InputGroup, InputRightElement, useToast} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
import {axiosLogin} from "../axios/users";
import {useStore} from "../store/store";

const LoginPage = () => {
    document.title = "Sign in";
    const [show, setShow] = useState(false)
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const handleClick = () => setShow(!show)
    const navigate = useNavigate();
    const toast = useToast()
    const {setUser} = useStore()

    const loginMethod = async () => {
        const loginPromise = axiosLogin({"login": login, "password": password});
        toast.promise(loginPromise, {
            success: {title: 'Успешный вход', description: 'Добро пожаловать!', duration: '1000'},
            error: {title: 'Ошибка входа', description: 'Неверные учетные данные'},
            loading: {title: 'Вход...', description: 'Пожалуйста, подождите'},
        });
        try {
            let data = await loginPromise;
            localStorage.setItem("auth", data.data.token)
            let user = data.data
            user.authenticated=true
            setUser(user)
            setTimeout(() => {
                navigate('/');
            }, [500])
        } catch (err) {
        }
    }
    return (
        <div className={s.login}>
            <div className={s.image}>
                <img src="./assets/images/login.webp" alt="car"/>
                <p>E-Motion<br/> Auto</p>
            </div>
            <div className={s.formBlock}>
                <div>
                    <div className={s.title}>
                        <Heading size={'lg'}>Sign in</Heading>
                    </div>
                    <div className={s.form}>
                        <Input
                            className={'br'}
                            size='sm'
                            placeholder={'Login'}
                            value={login}
                            onInput={(e) => setLogin(e.target.value)}
                        />
                        <InputGroup size='sm'>
                            <Input
                                value={password}
                                onInput={(e) => setPassword(e.target.value)}
                                className={'br'}
                                pr='4.5rem'
                                type={show ? 'text' : 'password'}
                                placeholder='Password'
                            />
                            <InputRightElement width='4.5rem'>
                                <Button
                                    variant={'outline'}
                                    h='1.75rem' size='sm'
                                    onClick={handleClick}
                                    border={'none'}
                                >
                                    {show ? <ViewIcon/> : <ViewOffIcon/>}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <Button className={['br', s.loginBtn].join(' ')} size='sm' variant='outline'
                                onClick={loginMethod}> Sign in</Button>
                        <div className={s.email}>
                            <p style={{fontWeight: 'bold', fontSize: '13px'}}>Contact us:</p>
                            <a href="mailto:astankevich@e-motion.pl">astankevich@e-motion.pl</a>
                        </div>

                    </div>

                </div>

                <div className={s.info}>
                    <p>E-MOTION AUTO SP. Z O.O.</p>
                    <p>ul. Domaniewska 17/19 lok. 133</p>
                    <p>02-672 Warszawa</p>
                    <p>NIP: PL5214055343</p>
                </div>
            </div>

        </div>
    );
};

export default LoginPage;