import React from 'react';
import s from './MyInputDateAddCar.module.scss'
import {Input} from "@chakra-ui/react";
import {useStore} from "../../../../../../store/store";

const MyInputDateAddCar = ({placeholder, stateName}) => {
    const {addCarData, setAddCarData} = useStore()
    return (
        <div className={s.input}>
            <label>
                <h6>{placeholder} <span>*</span></h6>
            </label>
            <Input
                style={{color:addCarData[stateName]?'#0F1028':'#898DB9'}}
                value={addCarData[stateName]}
                onInput={(e)=>setAddCarData({...addCarData,[stateName]:e.target.value})}
                required={true}
                placeholder={' '}
                size='xs'
                type='date'
            />
        </div>
    );
};

export default MyInputDateAddCar;