import React from 'react';
import {Heading} from "@chakra-ui/react";
import AddUserForm from "./addUserForm/AddUserForm";

const AddUser = () => {
    return (
        <div style={{marginLeft:'30px'}}>
            <Heading fontWeight={'normal'} size={'xl'}>Дабавить польователя</Heading>
            <AddUserForm/>
        </div>
    );
};

export default AddUser;