import React, {useEffect, useState} from 'react';
import s from './NotifyList.module.scss'
import {Accordion, AccordionButton, AccordionItem, AccordionPanel, useToast} from "@chakra-ui/react";
import {axiosNotify, axiosNotifyChecked, axiosNotifyDelete} from "../../../../../axios/notify";
import {formatDate} from "../../../../../utils/utils";
import MySpinner from "../../../../UI/mySpinner";
import {useStore} from "../../../../../store/store";

const NotifyList = () => {
    const [notify, setNotify] = useState([])
    const toast = useToast()
    const [loading,setLoading] = useState(false)
    const {decrementPopulation, user} = useStore()

    useEffect(() => {
        if (user.role === 1) {
            getAllNotify(true)
        }
        // eslint-disable-next-line
    }, []);

    const getAllNotify = async (load) => {
        if (load){
            setLoading(true)
        }

        const notifyPromise = axiosNotify();
        try {
            let data = await notifyPromise
            setNotify(data.data)
            if (load){
                setLoading(false)
            }

        } catch (e) {
            toast({
                title: 'Ошибка',
                description: 'Ошибка получения данных',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            if (load){
                setLoading(false)
            }
        }
    }
    const deleteMethod = (e, el) => {
        e.stopPropagation()
        const deletePromise = axiosNotifyDelete(el.id);
        toast.promise(deletePromise, {
            success: {title: 'Успех', description: 'Уведомление удалено', duration: '1000'},
            error: {title: 'Ошибка', description: 'Ошибка удаления', duration: '1000'},
            loading: {title: 'Удаление...', description: 'Пожалуйста, подождите'},
        });
        setTimeout(() => {
            getAllNotify()
        }, [100])


    }

    const checkedNotify = async (e, el) => {
        if (el.checked) {
            return
        }
        let target = e.currentTarget
        if (target.className.indexOf(s.checked) === -1){
            decrementPopulation()
            await axiosNotifyChecked(el.id).then()
        }
        target.classList.add(s.checked)


    }

    return (
        <div className={s.list}>
            <Accordion allowMultiple>
                {
                    loading
                        ? <MySpinner/>
                        : <>{
                                notify.length
                                ? notify.sort((a,b)=>b.id -a.id).map((el) =>
                                    <AccordionItem key={el.id} className={s.item}>
                                        <h2>
                                            <AccordionButton
                                                onClick={(e) => checkedNotify(e, el)}
                                                className={[s.title, el.checked ? s.checked : ''].join(' ')}
                                            >
                                                <div>
                                                    <div>
                                                        <p>id</p>
                                                        <p>{el.id}</p>
                                                    </div>
                                                    <div>
                                                        <p>Дата</p>
                                                        <p>{formatDate(el.date)}</p>
                                                    </div>
                                                    <div>
                                                        <p>Тип</p>
                                                        <p>{el.type}</p>
                                                    </div>
                                                    {el.name && <div><p>Имя</p><p>{el.name}</p></div>}
                                                    {el.phone && <div><p>Телефон</p><p>{el.phone}</p></div>}
                                                    {el.email && <div><p>Email</p><p>{el.email}</p></div>}
                                                    {el.car && <div><p>Авто</p><p>{el.car}</p></div>}
                                                </div>

                                                <div className={s.btnDelete} onClick={(e) => deleteMethod(e, el)}>Удалить
                                                </div>
                                            </AccordionButton>
                                        </h2>
                                        {el?.comment?.length > 0 && <AccordionPanel className={s.content} pb={4}>
                                            {el.comment}
                                        </AccordionPanel>}

                                    </AccordionItem>
                                )
                                : ""
                        }
                        </>
                }


            </Accordion>
        </div>
    );
};

export default NotifyList;