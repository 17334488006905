import axios from "axios";

export const API_URL = "https://api.e-motion.pl/"
//export const API_URL = "https://e-motion-api.it-global.by/"
export const axiosInstance = axios.create({
    baseURL: API_URL,
})

axiosInstance.interceptors.request.use(function (config) {
    config.headers['x-auth'] = localStorage.getItem('auth');
    return config;
});
