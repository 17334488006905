import React from 'react';
import Footer from "../../../footer/Footer";

const News = () => {
    return (
        <div>
            News
            <Footer/>
        </div>
    );
};

export default News;