import React, {useEffect} from 'react';
import s from './SideBar.module.scss'
import {Button} from "@chakra-ui/react";
import {useStore} from "../../../store/store";
import {axiosNotifyNew} from "../../../axios/notify";

const SideBar = () => {
    const {
        menuId,
        updateMenuId,
        resetSelectedFilters,
        resetCarPageId,
        user,
        countNotification,
        setNotification,
        resetAddCarData
    } = useStore()
    const selectMenu = (id) => {
        resetSelectedFilters()
        resetCarPageId()
        updateMenuId(id)
        resetAddCarData()
    }
    useEffect(() => {
        if (user.role === 1) {
            const fetchNotifications = async () => {
                axiosNotifyNew().then((data) => {
                    setNotification(data.data);
                })
            };
            fetchNotifications();
            const intervalId = setInterval(fetchNotifications, 30000);
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line
    }, []);
    return (
        <div className={s.sidebar}>
            <nav>
                <Button onClick={() => selectMenu(1)}
                        className={[s.item, menuId === 1 ? s.active : ''].join(' ')} variant={'ghost'}>Коммерческий
                    транспорт</Button>
                <Button onClick={() => selectMenu(2)}
                        className={[s.item, menuId === 2 ? s.active : ''].join(' ')} variant={'ghost'}>Легковой
                    транспорт</Button>
                {/*<Button onClick={() => selectMenu(4)}*/}
                {/*        className={[s.item, menuId === 4  ?  s.active : ''].join(' ')}*/}
                {/*        variant={'ghost'}>Новости</Button>*/}
                {
                    user.role === 1 &&
                    <>
                        <Button onClick={() => selectMenu(3)}
                                className={[s.item, menuId === 3 ? s.active : ''].join(' ')}
                                variant={'ghost'}>Уведомления <span>{countNotification && countNotification > 0 ? countNotification : ''}</span></Button>
                        <Button onClick={() => selectMenu(5)}
                                className={[s.item, menuId === 5 ? s.active : ''].join(' ')} variant={'ghost'}>Добавить
                            авто
                        </Button>
                        <Button onClick={() => selectMenu(6)}
                                className={[s.item, menuId === 6 ? s.active : ''].join(' ')} variant={'ghost'}>Добавить
                            пользователя
                        </Button>
                    </>
                }

            </nav>
        </div>
    );
};

export default SideBar;