import React, { useState } from 'react';
import s from './ImageUploader.module.scss';
import { Button } from '@chakra-ui/react';

const ImageUploader = ({ propsRefFile }) => {
    const [images, setImages] = useState([]);

    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        const newImages = files.map((file) => ({
            file,
            preview: URL.createObjectURL(file),
        }));
        setImages((prevImages) => [...prevImages, ...newImages]);
    };

    const handleRemoveImage = (index) => {
        setImages((prevImages) => {
            const updatedImages = prevImages.filter((_, i) => i !== index);

            // Update the input file with remaining images
            const dataTransfer = new DataTransfer();
            updatedImages.forEach((image) => {
                dataTransfer.items.add(image.file);
            });
            propsRefFile.current.files = dataTransfer.files;

            return updatedImages;
        });
    };

    const click = () => {
        propsRefFile.current.click();
    };

    return (
        <div className={s.input}>
            <input
                required={true}
                ref={propsRefFile}
                style={{ width: '1px', height: '1px', pointerEvents: 'none', opacity: 0 }}
                type="file"
                multiple
                accept="image/*"
                onChange={handleImageUpload}
            />
            <div className={s.imageBlock}>
                <Button className={s.addImage} onClick={click}>+</Button>
                {images.map((image, index) => (
                    <div className={s.item} key={index}>
                        <img
                            src={image.preview}
                            alt="Preview"
                        />
                        <button
                            type={'button'}
                            onClick={() => handleRemoveImage(index)}
                        >
                            X
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageUploader;
